<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center"
        :style="`background-image: url(${sideImg});background-size: cover;background-position: 50%;border-radius: 0px 32px 32px 0px;`"
      >
        <div class="left--side__wrapper p-3">
          <div class="wrapper__side">
            <div>
              <img class="mb-1" src="@/assets/images/logo.png" alt="logo" width="95">
              <hr
                class="w-25 m-0"
                style="border: 1px solid #E79242;"
              >
              <div class="my-3">
                <h6
                  class="size14 fw-bold-400"
                  style="line-height: 23px;"
                >
                  Khusus di desain untuk manajemen Toko Bangunan. <br>
                  Praktis, mudah dan lengkap.
                </h6>
              </div>
            </div>
            <div>
              <h5 class="size14 fw-bold-400 mb-1">
                Sudah punya akun ?
              </h5>
              <h5 class="size16 fw-bold-600" @click="$router.push({ name: 'login' })" role="button">
                Masuk Sekarang
              </h5>
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="10"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="fw-bold-800 mb-1 text-dark size20"
          >
            Ubah Password
          </b-card-title>
          <b-card-text class="mb-2 text-dark text-darken-4 size14 mb-md-5 mb-0">
            Masukan Password baru Anda
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <b-form-group class="custom__form--input mb-2">
                <label for="login-password">Password <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="formPayload.password"
                      :type="passwordFieldType"
                      class="form-control-merge custom__input"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="Password"
                      style="height: unset;"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="custom__form--input mb-2">
                <label for="login-password">Password <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="formPayload.password_confirmation"
                      :type="passwordFieldType"
                      class="form-control-merge custom__input"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="Password"
                      style="height: unset;"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                id="button--register"
                type="submit"
                variant="secondary"
                block
                :disabled="isLoading"
                class="size14 py-1 border-8 d-flex justify-content-center align-items-center"
                @click="validationForm"
              >
                <div
                  v-if="isLoading"
                  class="d-flex justify-content-center mr-1"
                >
                  <b-spinner
                    small
                    label="Loading..."
                  />
                </div>
                Lanjut
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Jika ada kendala terkait login bisa </span>
            <a
              class="text-dark text-darken-6"
              :href="'https://wa.me/6281807070900'"
              target="_blank"
            >
              &nbsp;hubungi admin
            </a>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BForm, BButton, BSpinner, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      terms_condition: false,
      agreement: false,
      sideImg: require('@/assets/images/background.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      handphone: '',
      formPayload: {
        password: '',
        password_confirmation: '',
      },
    }
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    handphone(val) {
      const digit0 = String(val).charAt(0)
      const digit1 = Number(digit0)

      if (digit1 === 0) {
        // phone = phone.substring(1)
        this.formPayload.operator_phone = `62${val.substring(1)}`
      } else {
        this.formPayload.operator_phone = val
      }
    },
  },
  methods: {
    async validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        this.isLoading = true
        if (success) {
          try {
            const response = await this.$http.post('/reset-password', this.formPayload, {
              params: {
                phone: this.$route.query.phone,
                token: this.$route.query.token,
              },
            })

            useJwt.setToken(response.data.data.token)
            localStorage.removeItem('operator_phone', this.formPayload.operator_phone)
            localStorage.removeItem('otp_type', this.formPayload.otp_type)
            localStorage.removeItem('expired_at', response.data.data.expired_at)
            // useJwt.setToken(response.data.data.token)
            successNotification(this, 'Success', 'Password anda berhasil diubah')
            this.$router.push('/welcome')
            this.isLoading = false
          } catch (error) {
            this.isLoading = false
          }
        } else {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.image__cover--login {
  border-radius: 0px 32px 32px 0px;
  object-fit: cover;
  height: 100vh;
}
.left--side__wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(21, 38, 61, 0.2);
  backdrop-filter: blur(44.3121px);
  border-radius: 20.257px;
  width: 420px;

  .wrapper__side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      font-family: 'Medula One', sans-serif !important;
      font-size: 50px;
    }

    h5, h6 {
      color: #fff;
      font-family: 'Be Vietnam Pro', sans-serif !important;
    }
  }
}
</style>
